import { FC, HTMLAttributes, ReactNode } from "react";

interface TopBarProps extends HTMLAttributes<HTMLDivElement> {
  rightSlot?: ReactNode;
  bottomSlot?: ReactNode;
}

export const TopBar: FC<TopBarProps> = ({
  children,
  rightSlot,
  bottomSlot,
  ...rest
}) => {
  return (
    <div
      className="flex flex-col gap-2"
      role="toolbar"
      aria-label="Top bar controls"
      {...rest}
    >
      <div className={"flex items-center"} role="presentation">
        <div
          className="flex items-center gap-4 flex-wrap gap-y-2 flex-1"
          role="group"
          aria-label="Primary controls"
        >
          {children}
        </div>
        <div role="group" aria-label="Secondary controls">
          {rightSlot}
        </div>
      </div>
      {bottomSlot}
    </div>
  );
};
