import { ChangeEventHandler, HTMLAttributes, ReactNode, useState } from "react";

import {
  ChevronDownIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";

import { cn } from "../../../utils";
import { Badge, Separator, TruncatedBadge, TruncatedText } from "../../content";
import { Popover, PopoverContent, PopoverTrigger } from "../../content/popover";
import { FilterOption } from "../../types";
import { Checkbox, Input } from "..";

export enum FilterDropdownMode {
  MULTIPLE = "multiple",
  SINGLE = "single",
}

interface FilterDropdownProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  icon?: ReactNode;
  options?: FilterOption[];
  selectedValues?: string[];
  disabled?: boolean;
  placeholder?: string;
  hideClearOption?: boolean;
  mode?: FilterDropdownMode;
  classNames?: {
    container?: string;
  };
  maxBadges?: number;
  onToggleOption: (option: FilterOption) => void;
  onClear: () => void;
  badgePosition?: "side" | "bottom";
  side?: "auto" | "top" | "right" | "bottom" | "left";
  collisionPadding?: number;
  sideOffset?: number;
  filterRightSlot?: ReactNode;
}

export const FilterDropdown = ({
  label,
  icon,
  options = [],
  selectedValues = [],
  disabled,
  placeholder = "Search...",
  hideClearOption,
  mode = FilterDropdownMode.MULTIPLE,
  classNames,
  onToggleOption,
  onClear,
  badgePosition = "side",
  maxBadges,
  side = "auto",
  collisionPadding = 30,
  sideOffset = 0,
  filterRightSlot,
  ...rest
}: FilterDropdownProps) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const labelsMap = options.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {} as Record<string, string>);

  const renderFilterBadges = (maxBadges = 3) => {
    if (selectedValues.length > maxBadges) {
      return (
        <Badge variant={"filter"}>+{selectedValues.length} Selected</Badge>
      );
    }

    return selectedValues.map((value) => (
      <TruncatedBadge key={value} variant={"filter"} textLength={20}>
        {labelsMap?.[value] ?? value}
      </TruncatedBadge>
    ));
  };

  const handleOptionToggle = (option: FilterOption) => {
    if (mode === FilterDropdownMode.SINGLE) {
      if (selectedValues.includes(option.value)) {
        onClear();
      } else {
        onToggleOption(option);
      }
    } else {
      onToggleOption(option);
    }
  };

  const renderFilterOptionButton = (option: FilterOption) => (
    <div
      key={`filter-option-${option.label}`}
      data-testid={`filter-option-${option.label}`}
      data-selected={selectedValues.includes(option.value)}
      onClick={() => handleOptionToggle(option)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          handleOptionToggle(option);
        }
      }}
      tabIndex={0}
      className={
        "py-1.5 px-[8px] flex justify-between items-center cursor-pointer text-left"
      }
      role="menuitemcheckbox"
      aria-checked={selectedValues.includes(option.value)}
    >
      <div className={"flex items-center gap-[8px]"}>
        <Checkbox
          checked={selectedValues.includes(option.value)}
          label={<TruncatedText textLength={24}>{option.label}</TruncatedText>}
          labelClassName="line-clamp-1"
          onClick={() => handleOptionToggle(option)}
        />
      </div>
      {option.count !== undefined && (
        <div>
          <span aria-label={`${option.count} items`}>{option.count}</span>
        </div>
      )}
    </div>
  );

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) =>
    setSearchValue(e.target.value);

  const renderOptions = () => {
    if (options.length === 0) {
      return (
        <span
          className={
            "text-muted-foreground text-xs font-medium text-center p-1"
          }
        >
          No options available
        </span>
      );
    }

    return options
      .filter(
        (option) =>
          option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
          option.value.toLowerCase().includes(searchValue.toLowerCase())
      )
      .sort((a, b) => {
        if (!searchValue) {
          const aSelected = selectedValues.includes(a.value);
          const bSelected = selectedValues.includes(b.value);

          if (aSelected && !bSelected) return -1;
          if (!aSelected && bSelected) return 1;
        }
        return 0;
      })
      .map(renderFilterOptionButton);
  };

  return (
    <motion.div
      layout
      layoutDependency={selectedValues.length}
      layoutRoot
      transition={{ duration: 0.2, bounce: 0 }}
      className={cn(
        "border border-dashed text-foreground bg-background rounded-[6px] flex items-center p-2 gap-1 overflow-hidden",
        selectedValues.length > 0 && "pr-2",
        badgePosition === "bottom" && "flex flex-col",
        classNames?.container
      )}
      {...(rest as HTMLMotionProps<"div">)}
    >
      <Popover>
        <PopoverTrigger disabled={disabled} asChild>
          <div
            role="button"
            data-testid="filter-dropdown-button"
            className={"flex items-center h-full gap-2.5 p-0 w-full"}
            aria-label={`${label} filter dropdown${
              selectedValues.length > 0
                ? `, ${selectedValues.length} selected`
                : ""
            }`}
            tabIndex={0}
            aria-expanded="false"
            aria-haspopup="true"
          >
            {icon ?? (
              <DocumentIcon
                className={"w-4 h-4 text-foreground"}
                aria-hidden="true"
              />
            )}
            <span
              className={"text-foreground text-xs font-medium flex-1 text-left"}
            >
              {mode === FilterDropdownMode.SINGLE
                ? labelsMap[selectedValues[0]] ?? selectedValues[0] ?? label
                : label}
            </span>
            {filterRightSlot ?? (
              <ChevronDownIcon
                className="w-4 h-4 text-muted-foreground"
                aria-hidden="true"
              />
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          collisionPadding={collisionPadding}
          sideOffset={sideOffset}
          align={"start"}
          side={side === "auto" ? undefined : side}
          className={"shadow-md p-0 flex flex-col w-[223px]"}
          role="menu"
        >
          <div className={"px-3 pt-3 pb-2 border-b flex items-center gap-2"}>
            <MagnifyingGlassIcon
              className={"w-[14px] h-[14px] text-muted-foreground"}
              aria-hidden="true"
            />
            <Input
              className={"border-none p-0 h-auto"}
              value={searchValue}
              onChange={handleSearch}
              placeholder={placeholder}
              aria-label={`Search ${label} options`}
            />
          </div>
          <div
            className={"flex flex-col p-1 gap-1 max-h-[300px] overflow-y-auto"}
            role="group"
            aria-label={`${label} options`}
          >
            {renderOptions()}
          </div>
          <AnimatePresence initial={false}>
            {selectedValues.length > 0 && !hideClearOption && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{
                  duration: 0.2,
                  ease: "easeInOut",
                }}
                className="overflow-hidden border-t"
              >
                <button
                  className={
                    "w-full p-3 hover:bg-secondary-hover transition-colors"
                  }
                  onClick={onClear}
                  aria-label="Clear selected filters"
                >
                  <span>Clear Results</span>
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        </PopoverContent>
      </Popover>

      <AnimatePresence>
        {selectedValues.length > 0 && mode === FilterDropdownMode.MULTIPLE && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className={cn("w-full", badgePosition === "side" && "w-auto")}
          >
            {badgePosition === "side" ? (
              <div className="flex items-center gap-1">
                <Separator
                  orientation={"vertical"}
                  className={"h-[20px] bg-primary-foreground"}
                />
                {renderFilterBadges(maxBadges)}
              </div>
            ) : (
              <div className="flex flex-col w-full gap-1">
                <Separator
                  orientation={"horizontal"}
                  className={"w-full bg-primary-foreground"}
                />
                <div className="flex items-center gap-2">
                  {renderFilterBadges(maxBadges)}
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
