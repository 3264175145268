import {
  cloneElement,
  FC,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from "react";

import { motion } from "framer-motion";

import { cn } from "../../../utils";

interface TabSelectorOption {
  label?: ReactNode;
  value: string;
  icon?: ReactNode;
  enabledIconColor?: string;
}

interface TabsSelectorProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onSelect"> {
  value: string;
  options: TabSelectorOption[];
  onSelect: (id: string) => void;
}

export const TabsSelector: FC<TabsSelectorProps> = ({
  options,
  onSelect,
  value,
  ...rest
}) => {
  const selectedIndex = options.findIndex((option) => option.value === value);

  return (
    <motion.div
      layout
      layoutDependency={value}
      className={cn(
        "grid p-1 bg-muted rounded-[6px] w-full relative",
        rest.className
      )}
      style={{
        gridTemplateColumns: `repeat(${options.length}, 1fr)`,
      }}
      role="tablist"
      data-testid="tabs-selector"
    >
      <motion.div
        className="absolute bg-background rounded-[3px] border border-muted shadow-sm"
        layoutId="tab-background"
        layoutDependency={value}
        transition={{ type: "spring", duration: 0.5, bounce: 0 }}
        style={{
          width: "100%",
          gridColumn: `${selectedIndex + 1} / span 1`,
          height: "calc(100% - 8px)",
          top: "4px",
        }}
      />
      {options.map(
        ({ value: itemValue, icon, label, enabledIconColor }, index) => (
          <button
            key={itemValue}
            onClick={() => onSelect(itemValue)}
            role="tab"
            aria-selected={value === itemValue}
            data-testid={`tab-${itemValue}`}
            className={cn(
              "flex gap-1 items-center justify-center py-1 px-2 rounded-[3px] w-full relative z-10"
            )}
          >
            {icon &&
              cloneElement(icon as ReactElement, {
                "data-testid": `icon-${itemValue}`,
                className: cn(
                  `w-4 h-4 text-muted-foreground text-${enabledIconColor} opacity-50`,
                  value === itemValue && "opacity-100"
                ),
              })}
            {label && (
              <span
                data-testid={`label-${itemValue}`}
                className={
                  "text-[12px] font-normal text-muted-foreground leading-[13px]"
                }
              >
                {label}
              </span>
            )}
          </button>
        )
      )}
    </motion.div>
  );
};
