import { SVGProps } from "react";

export const RefreshIcon = ({
  className = "",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={`stroke-current ${className}`}
    {...props}
  >
    <path
      d="M2 6.49609L3.5 8.74609L5.25 6.74609"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 12.75C9.18946 12.75 10.1078 12.4714 10.889 11.9495C11.6701 11.4275 12.2789 10.6857 12.6384 9.81775C12.9979 8.9498 13.092 7.99473 12.9087 7.07332C12.7255 6.15191 12.2731 5.30554 11.6088 4.64124C10.9445 3.97695 10.0981 3.52455 9.17668 3.34127C8.25527 3.15799 7.3002 3.25206 6.43225 3.61157C5.5643 3.97109 4.82246 4.57991 4.30052 5.36104C3.77858 6.14218 3.5 7.06054 3.5 8V8.747"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
