import {
  ChangeEvent,
  cloneElement,
  createElement,
  FC,
  HTMLAttributes,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { AnimatePresence, motion } from "framer-motion";
import { isEmpty, trim } from "lodash";
import { twMerge } from "tailwind-merge";

import { cn } from "../../../utils";
import { Input } from "./input";

export interface EditableProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  children?: ReactElement;
  onChange?: (value?: string) => void;
  inputClassName?: string;
  triggerClassName?: string;
  value?: string;
  disabled?: boolean;
  label?: string;
  padding?: number;
}

export const Editable: FC<EditableProps> = ({
  children,
  onChange,
  inputClassName,
  triggerClassName,
  value,
  disabled,
  label = "Editable text",
  padding = 20,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    value ?? children?.props.children
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const clonedElement = useMemo(() => {
    return cloneElement(children ?? createElement("span"), {
      onDoubleClick: () => {
        if (!disabled) {
          const width =
            containerRef.current?.getBoundingClientRect().width || 0;
          setContainerWidth(width);
          setIsEditing(true);
        }
      },
    });
  }, [children, currentValue, disabled, value]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
  };

  const save = useCallback(() => {
    if (onChange && !isEmpty(currentValue)) {
      onChange(currentValue);
    }
  }, [onChange, currentValue, value]);

  const handleOnBlur = useCallback(() => {
    setIsEditing(false);
    save();
  }, [save]);

  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (
        (e.key === "Enter" || e.key === "Escape") &&
        !isEmpty(trim(currentValue))
      ) {
        setIsEditing(false);
        if (e.key === "Enter") {
          save();
        }
      }
    },
    [currentValue, save]
  );

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (!disabled && (e.key === "Enter" || e.key === " ")) {
      e.preventDefault();
      const width = containerRef.current?.getBoundingClientRect().width || 0;
      setContainerWidth(width);
      setIsEditing(true);
    }
  };

  return (
    <div
      {...props}
      className={cn("inline-block relative", props.className)}
      role="group"
      aria-label={label}
    >
      <AnimatePresence initial={false}>
        {isEditing ? (
          <motion.div
            key="editing"
            initial={{ width: containerWidth, opacity: 0 }}
            animate={{ width: containerWidth + padding, opacity: 1 }}
            exit={{ width: containerWidth, opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="flex-1 absolute top-0 left-0 z-10 !p-0"
            onAnimationComplete={() => {
              inputRef.current?.focus();
            }}
          >
            <Input
              ref={inputRef}
              className={twMerge("w-full border-primary", inputClassName)}
              containerClassName={"flex-1"}
              defaultValue={currentValue}
              disabled={disabled}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDown}
              aria-label={`Edit ${label}`}
              data-testid={`${label}-input`}
            />
          </motion.div>
        ) : null}

        <motion.div
          key="display"
          initial={{ opacity: isEditing ? 0 : 1 }}
          animate={{ opacity: isEditing ? 0 : 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          style={{ visibility: isEditing ? "hidden" : "visible" }}
        >
          <div
            className={cn(
              "rounded-lg cursor-pointer px-4 py-1",
              "hover:bg-muted transition-colors",
              disabled && "cursor-not-allowed opacity-50",
              triggerClassName
            )}
            ref={containerRef}
            role="button"
            tabIndex={disabled ? -1 : 0}
            onKeyDown={handleKeyPress}
            aria-label={`Double click or press Enter to edit ${label}`}
            aria-disabled={disabled}
          >
            {clonedElement}
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
