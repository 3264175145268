/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useCallback, useEffect, useState } from "react";
import { SelectRangeEventHandler } from "react-day-picker";

import { CalendarDaysIcon } from "@heroicons/react/24/outline";

import dayjs, { Dayjs } from "dayjs";
import {
  FieldHelperProps,
  FieldInputProps,
  useField,
  useFormikContext,
} from "formik";

import { cn } from "../../../utils";
import { Button } from "../../content/button";
import { Popover, PopoverContent, PopoverTrigger } from "../../content/popover";
import { Calendar } from "./calendar/calendar";

type DateValue = string | number | Date | Dayjs;

interface DatepickerProps {
  name?: string;
  value?: DateValue;
  values?: DateValue[];
  onChange?: (date: Date | Date[]) => void;
  isDateSpan?: boolean;
  className?: string;
}

type DateRange = { from: Date; to: Date } | undefined;

const parseDateValue = (value: DateValue | undefined): Dayjs | undefined => {
  if (!value) return undefined;
  if (dayjs.isDayjs(value)) return value;
  return dayjs(value);
};

export const DatePicker = forwardRef<HTMLButtonElement, DatepickerProps>(
  (
    { name, value, values, onChange, isDateSpan = false, className, ...props },
    ref
  ) => {
    const [internalValues, setInternalValues] = useState<Dayjs[]>([]);
    const [displayMonth, setDisplayMonth] = useState(() => dayjs());
    let field: FieldInputProps<any> | undefined = undefined;
    let helpers: FieldHelperProps<any> | undefined = undefined;

    // Always call useFormikContext and useField
    const formik = useFormikContext();

    try {
      [field, , helpers] = useField(name || "dummyField");
    } catch (error) {
      field = undefined;
      helpers = undefined;
    }

    useEffect(() => {
      let newValues: Dayjs[] = [];

      if (name && formik) {
        // Formik field value
        const fieldValue = field?.value;
        newValues = Array.isArray(fieldValue)
          ? fieldValue.map((v) => parseDateValue(v) || dayjs())
          : [parseDateValue(fieldValue) || dayjs()];
      } else if (values) {
        // Prop values
        newValues = values.map((v) => parseDateValue(v) || dayjs());
      } else if (value !== undefined) {
        // Prop value
        const parsedValue = parseDateValue(value);
        newValues = parsedValue?.isValid() ? [parsedValue] : [];
      }

      setInternalValues(newValues);
      setDisplayMonth(newValues[0] || dayjs());
    }, [name, formik, field?.value, value, values]);

    const formatValue = useCallback(() => {
      const [start, end] = internalValues;
      if (!start?.isValid()) {
        return "Select a date";
      }

      const startFormat = start.format("MMM DD, YYYY");
      return isDateSpan && end
        ? `${startFormat} - ${end.format("MMM DD, YYYY")}`
        : startFormat;
    }, [internalValues, isDateSpan]);

    const dateRange: Date | DateRange = isDateSpan
      ? { from: internalValues[0]?.toDate(), to: internalValues[1]?.toDate() }
      : internalValues[0]?.toDate();

    const handleDateChange = useCallback(
      (date: Date | DateRange) => {
        if (!date) return;

        let newValues: Date[];
        if ("from" in date) {
          newValues = [date.from, date.to].filter(Boolean);
        } else {
          newValues = [date];
        }

        const newDayjsValues = newValues.map((d) => dayjs(d));
        setInternalValues(newDayjsValues);

        if (name && formik) {
          // Update Formik field
          helpers?.setValue(isDateSpan ? newValues : newValues[0]);
        } else if (onChange) {
          // Call onChange prop
          onChange(isDateSpan ? newValues : newValues[0]);
        }
      },
      [name, formik, helpers, onChange, isDateSpan]
    );

    const handleMonthChange = useCallback(
      (date: Date) => {
        setDisplayMonth(dayjs(date));

        if (internalValues[0]?.isValid()) {
          const targetDay = internalValues[0].date();
          const newMonth = dayjs(date);
          const lastDayOfMonth = newMonth.endOf("month").date();
          const adjustedDay = Math.min(targetDay, lastDayOfMonth);
          const newDate = newMonth.date(adjustedDay).toDate();

          handleDateChange(
            isDateSpan
              ? { from: newDate, to: internalValues[1]?.toDate() }
              : newDate
          );
        }
      },
      [internalValues, handleDateChange, isDateSpan]
    );

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            ref={ref}
            className={cn(
              "h-[32px] px-3 py-2 flex items-center justify-start gap-[10px]",
              className
            )}
          >
            <CalendarDaysIcon className="w-4 h-4 text-foreground" />
            <span className="text-xs font-medium text-foreground leading-[21px]">
              {formatValue()}
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex-col w-auto relative mr-8 min-h-[360px]">
          <div className="flex gap-4">
            {(isDateSpan ? [0, 1] : [0]).map((offset) => (
              <Calendar
                key={offset}
                mode={(isDateSpan ? "range" : "single") as any}
                month={displayMonth.add(offset, "month").toDate()}
                selected={dateRange}
                onMonthChange={handleMonthChange}
                onSelect={handleDateChange as SelectRangeEventHandler}
              />
            ))}
          </div>
        </PopoverContent>
      </Popover>
    );
  }
);

DatePicker.displayName = "DatePicker";
