import { FC, ReactNode } from "react";

import { AlertDialogProps } from "@radix-ui/react-alert-dialog";

import { cn } from "../../../utils";
import { Dialog, DialogContent } from "./dialog";
import { DialogDescription } from "./dialog-description";

export interface BaseDialogProps extends AlertDialogProps {
  title?: ReactNode;
  children: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  addons?: ReactNode;
  className?: string;
}

export const BaseDialog: FC<BaseDialogProps> = ({
  title,
  isOpen,
  onClose,
  className,
  children,
  addons,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent showCloseButton={false}>
        <DialogDescription
          data-testid="dialog-description"
          className={cn("flex flex-col", className)}
          asChild
        >
          <div>
            <div className="flex items-center gap-2">
              <div className="flex-1">
                <span className="text-lg font-semibold text-slate-900">
                  {title}
                </span>
              </div>
              {addons}
            </div>
            {children}
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};
