import {
  FocusEventHandler,
  forwardRef,
  HTMLAttributes,
  KeyboardEventHandler,
} from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { Input } from "../../actions/inputs/input";

interface SearchInputProps extends HTMLAttributes<HTMLInputElement> {
  placeholder: string;
  value?: string;
  onSearch: (searchValue: string) => void;
  onValueChange?: (value: string) => void;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ placeholder, value, onSearch, onValueChange, ...rest }, ref) => {
    const handleSearchInputBlur: FocusEventHandler<HTMLInputElement> = (
      event
    ) => onSearch?.(event.target.value);

    const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      if (event.key === "Enter") {
        onSearch?.(event.currentTarget.value);
      }
    };

    return (
      <Input
        ref={ref}
        className={"h-[36px] min-w-[260px]"}
        placeholder={placeholder ?? "Search..."}
        onBlur={handleSearchInputBlur}
        onKeyDown={handleSearchKeyDown}
        onChange={(event) => onValueChange?.(event.target.value)}
        value={value}
        type="search"
        role="searchbox"
        aria-label={placeholder ?? "Search"}
        endIcon={<MagnifyingGlassIcon className="w-[18px] h-[18px]" />}
        {...rest}
      />
    );
  }
);
