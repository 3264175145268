"use client";

import { forwardRef } from "react";

import { Root, Thumb } from "@radix-ui/react-switch";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../../utils";

const switchVariants = cva(
  "peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      size: {
        xs: "h-[16px] w-[28px]",
        md: "h-[24px] w-[44px]",
        lg: "h-[32px] w-[56px]",
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
);

const thumbVariants = cva(
  "pointer-events-none block rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-0",
  {
    variants: {
      size: {
        xs: "h-3 w-3 data-[state=checked]:translate-x-3",
        md: "h-5 w-5 data-[state=checked]:translate-x-5",
        lg: "h-7 w-7 data-[state=checked]:translate-x-6",
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
);

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof Root>,
    VariantProps<typeof switchVariants> {
  enabledColor?: string;
  disabledColor?: string;
}

export const Switch = forwardRef<React.ElementRef<typeof Root>, SwitchProps>(
  ({ className, size, enabledColor, disabledColor, style, ...props }, ref) => (
    <Root
      className={cn(switchVariants({ size, className }))}
      style={
        {
          ...style,
          backgroundColor: "var(--switch-bg)",
          "--switch-bg": props.checked
            ? enabledColor ?? "hsl(var(--primary))"
            : disabledColor ?? "hsl(var(--input))",
        } as React.CSSProperties
      }
      {...props}
      ref={ref}
    >
      <Thumb className={cn(thumbVariants({ size }))} />
    </Root>
  )
);

Switch.displayName = Root.displayName;
